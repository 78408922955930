<template>
    <confirm-box
        v-if="showConfirm"
        :title="messageTitle"
        :message="message"
        @hide-modal="onCloseMessage"
    >
    </confirm-box>
    <div  
      :class="gridContainerStyle"
    >
        <the-header 
          :me="me"
        ></the-header>
        <side-nav 
          @toggle-size="toggleMenuSize"
          :key="`sidenav-${me ? me.id : ''}`"
        ></side-nav>
        <main class="main">
          <router-view/>
        </main>
    </div>
</template>

<script>
import { computed, ref, provide } from 'vue'
import {store} from '@/store/store'
import { useRoute } from 'vue-router'
import SideNav from '@/components/SideNav'
import TheHeader from '@/components/TheHeader'
import ConfirmBox from '@/components/ConfirmBox'
import {
  showConfirm,
  message,
  messageTitle,
  onCloseMessage
} from '@/utils/MessagesFunctions'
import {getMainGridClassname} from '@/utils/StyleFunctions'

import { 
  ME,
  DO_LOGOUT, 
  IS_LOGGED, 
  TOKEN
} from './store/VuexPaths'


export default {
  name: 'App',
  components: {
    SideNav,
    TheHeader,
    ConfirmBox,
  },
  setup (){

    const route = useRoute()
    const isLoggedIn = computed(() => {
      return store.getters[IS_LOGGED]
    })

    const me = computed(()=> store.getters[ME])
    
    provide('me', me)
    
    const gridContainerStyle = computed(()=>{
      return getMainGridClassname(me, bigMenu)
      
    })

    const bigMenu = ref(false)

    const toggleMenuSize = () => {
      try {
        bigMenu.value = !bigMenu.value
      } catch (error) {
        console.log(error) 
      }
      
    }

    
    return {
      isLoggedIn,
      me,
      showConfirm,
      message,
      messageTitle,
      onCloseMessage,
      toggleMenuSize,
      gridContainerStyle,
      route,
    }
  },
  created: async function () {
    
    if(store.getters[TOKEN]){
      
      const expiration = new Date(store.getters['auth/expiration'])
      
      if(expiration.getTime() < Date.now()){  
        store.dispatch(DO_LOGOUT)
        this.$router.push('/login')
      }else {
        this.http.defaults.headers.common['Authorization'] = 'bearer ' + store.getters[TOKEN]
      }
     
    }else{
      
      if(!this.$router.currentRoute.value.path.startsWith('/auth/password/reset/')){
        await store.dispatch(DO_LOGOUT)
        
      }
    }
    this.http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          store.dispatch(DO_LOGOUT)
        }
        throw err
      })
    })
  },
}
</script>
<style lang="postcss">
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #00000087;
    z-index: 999;
}
</style>

