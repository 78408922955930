import {store} from '@/store/store.js'
import {resetResizerValues} from '@/utils/ColumnsResizer' 
const Campaigns = () => import(/* webpackChunkName: "campaigns" */ '@/views/Campaigns.vue')
export const campaigns ={
    path: '/campaigns',
    name: 'campaigns',
    component: Campaigns,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
        
        await store.dispatch('campaigns/index')
       /*  await store.dispatch('tags/getTags')
        await store.dispatch('social/list')
        await store.dispatch('post/index')
        await store.dispatch('user/users') */
        store.commit('campaigns/clear_filters')
        resetResizerValues('campaigns')
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }
  export const campaignByID ={
    path: '/campaigns/:id',
    name: 'campaign',
    component: Campaigns,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
          if(store.getters['campaigns/campaigns'].length === 0){
            await store.dispatch('campaigns/index')
          }
        resetResizerValues('campaigns')
        store.commit('campaigns/clear_filters')
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }