/**
 * UserScope
 *  
 */

const userscope_request=(state)=>{
    state.loading = true
}
const userscope_success=(state, {data, pagination})=>{
  state.loading = false
  //state.userScopes = data
  //state.pagination = pagination
  const {CurrentPage} = pagination 
    //prima navigazione
    if(!state.pagination){
      state.pagination = pagination
      state.userScopes = data
      state.visitedPages.push(CurrentPage)
    } else {
       state.pagination = pagination
      ///se non ho mai visto la paginata -> aggiungo i nuovi dati
      if(!state.visitedPages.find(p => p===CurrentPage)){
        state.visitedPages.push(CurrentPage)
         data.forEach(t =>{
          state.userScopes.push(t)
        }) 

      }else{
        ////altrimenti eseguo l'update sulla porzione di store
        data.forEach((tag, indx) =>{
          const toReplace = state.userScopes.find(t =>t.id === tag.id)
          if(toReplace){
            Object.assign(toReplace, tag)
          } else{
            state.userScopes[indx] = tag
          }
        })
      }
      
      state.pagination = pagination
    }
}
const userscope_error = (state)=>{
  state.loading = false
  //state.userScopes = []
}

const userscope_save_request=(state)=>{
  state.loading = true
}
const update_request=(state)=>{
  state.loading = true
}
const update_success=(state, {data})=>{
  
  state.loading = false
  const scope = state.userScopes.find(s => s.id === data.id)
  Object.assign(scope, data);
}
const userscope_save_success=(state, data)=>{
  
  state.loading = false
  state.userScopes.unshift(data)
  state.creating = false
}
const userscope_save_error = (state)=>{
  state.loading = false
  state.creating = false
}

const userscope_selected_role = (state, {role, discriminator}) =>{
  switch(role){
    case 'Superuser':
    case 'Admin':
    case 'Advertiser':
        state.available = null
        break;
    case 'ConcessionaireAdmin':
    case 'ConcessionaireUser':
    case 'PublisherAdmin':
    case 'PublisherUser':
        state.available = state.userScopes.filter(scope => scope.discriminator === discriminator)
        break 
  }
}

const filter_request = (state, {type, value})=>{
  state.loading = true
  const indx = state.activeFilters.findIndex(f => f.type === type) 
  const emptySearch = type === 'search' && value === ''
  const allRoles = type === 'discriminator' && value === 'Tutti'
  
  if(indx === -1){
      if(type === 'discriminator' && !allRoles){
        state.activeFilters.push({type:type, value:value})
      }
      if(type === 'search' && !emptySearch){
        state.activeFilters.push({type:type, value:value})
      }   
      
  }else {
    if(type === 'search'){
      if(emptySearch){
        state.activeFilters = state.activeFilters.filter(f => f.type !== 'search')
      } else {
        state.activeFilters[indx]['value'] = value
      }
    }
    if(type === 'discriminator'){
      
      if(allRoles){
        
        state.activeFilters = state.activeFilters.filter(f => f.type !== 'discriminator')
        
      } else{
        state.activeFilters[indx]['value'] = value
      }
    }
  }
}
const do_filter =(state, {data, pagination}) => {
  state.filtered = data
  state.pagination = pagination
}
const empty_filter = (state, type)=>{
  const indx = state.activeFilters.findIndex(f => f.type === type)
  if(indx !== -1){
    state.activeFilters = state.activeFilters.filter(f => f.type !== type)
  }
  if(state.activeFilters.length ===0){
    state.filtered = null
  }
}
const clear_filters =(state)=>{
  state.filtered=null
  state.activeFilters=[]
}

const do_sort =(state, {sorting}) => {
  switch(sorting.type){
    case 'Nome asc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => a.name.localeCompare(b.name))
      }else{
        state.userScopes = state.userScopes.sort((a, b) => a.name.localeCompare(b.name))
      }
      
    break
    case 'Nome desc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => b.name.localeCompare(a.name))
      }else{
        state.userScopes = state.userScopes.sort((a, b) => b.name.localeCompare(a.name))
      }
    break
    case 'data creazione asc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }else{
        state.userScopes = state.userScopes.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }
      
    break
    case 'data creazione desc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }else{
        state.userScopes = state.userScopes.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }
    break
  }
  
}

const userscope_clear =(state)=>{
  state.userScopes = []
}

const remove_error =(state)=>{
  state.loading = false
}
const remove_success =(state, userScope)=>{
  
  state.loading = false
  const userScopes = state.userScopes.filter(u => u.id !== userScope.id)
  state.userScopes = userScopes
}

const savecolumnsizes = (state, sizes)=>{
  state.columnsSizes = sizes
}

const upload_success = (state, {response})=>{
  state.loading = false
  const updated = state.userScopes.find(scope=> scope.id===response.userScopeId)
  if(updated.logo){
    updated.logo.id = response.id
    updated.logo.userScopeId = response.userScopeId
  }else{
    updated.logo = {
      id:response.id,
      userScopeId:response.userId
    }
  }
}

const single_get_success = (state, {data})=>{
  const toReplace = state.userScopes.find(us =>us.id === data.id) 
  Object.assign(toReplace, data);
}

export  const mutations = {
  userscope_request,
  userscope_success,
  userscope_error,
  userscope_selected_role,
  userscope_save_request,
  userscope_save_success,
  userscope_save_error,
  do_filter,
  do_sort,
  userscope_clear,
  update_request,
  update_success,
  remove_error,
  remove_success,
  savecolumnsizes,
  upload_success,
  single_get_success,
  empty_filter,
  filter_request,
  clear_filters
}  