
import {store} from '@/store/store.js'
import {resetResizerValues} from '@/utils/ColumnsResizer' 
const UserScopes = () => import(/* webpackChunkName: "userscopes" */ '@/views/UserScopes.vue')
export  const userscopes = {
    path: '/userscopes',
    name: 'userscopes',
    component: UserScopes,
    meta: {
        requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
        try {
        await store.dispatch('userscope/userScopes')

        resetResizerValues('userscope')
        store.commit('userscope/clear_filters')
        next();
        } catch(exception) {
        next(exception);
        }
    },
}
export  const userscopeByID = {
    path: '/userscope/:id',
    name: 'userscope',
    component: UserScopes,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
        if(store.getters['userscope/scopes'].length === 0){
          await store.dispatch('userscope/userScopes')
        }
        
        resetResizerValues('userscope', to.params.id)
        store.commit('userscope/clear_filters')
        next();
      } catch(exception) {
        next(exception);
      }
    },
  }