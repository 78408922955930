<template>
  <div :class="className">
    <img
      v-if="currentFileUrl"
      :src="currentFileUrl"
      class="usercard-img w100"
      @click.prevent.stop="onImageClick"
      ref="currentImage"
      loading="lazy"
    />
    <img
      v-else
      src="../css/img/placeholder.png"
      class="usercard-img w100"
      @click.prevent.stop="onImageClick"
      loading="lazy"
    />
    <div v-if="currentFileUrl && editing">
      <a class="bebas uploader-txt w33" @click.prevent="onImageClick">Cambia</a>
      <a class="bebas uploader-txt w33" @click.prevent="deleteImage">Elimina</a>
    </div>
    <div v-else-if="editing">
      <a class="bebas uploader-txt w50" @click.prevent="onImageClick">scegli</a>
      <div v-if="type === 'image'">
        <a class="bebas uploader-txt w50" @click.prevent="OpenImageAi"
          >Genera con AI</a
        >
        <ImageAi
          v-if="showImageAi"
          :AiData="ImageAiData"
          @close="CloseImageAi"
          @response-selected="handleDallEResponseSelected"
          @aidata-updated="ImageAiData = $event"
        >
        </ImageAi>
      </div>
    </div>
    <input
      :ref="refName"
      class="uploader-input"
      type="file"
      @change="fileSelected"
      multiple
    />
  </div>
</template>
<script>
// TODO:

// il componente è stato concepito con un po di confusione (Non da me).
// Da ora, se è un'immagine, va passato il props.type "image". Di default sarà "file" e funzionerà alla vecchia maniera

import { ref } from "vue";
import ImageAi from "@/components/ImageAi.vue";
export default {
  components: { ImageAi },
  emits: ["image-selected", "image-url-load", "image-deleted"],
  props: {
    type: {
      type: String,
      default: "file", // file or image
    },
    fileurl: {
      type: String,
      default: null,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default:
        "uploader-container w33 flex-container flex-col flex-center-align",
    },
    refName: {
      type: String,
      default: "uploaderInput",
    },
    IaImageDescription: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const showImageAi = ref(false);
    const uploaderInput = ref(null);
    const files = ref(null);
    const currentImage = ref(null);
    const currentFileUrl = props.fileurl ? ref(props.fileurl) : ref(null);

    const ImageAiData = ref(null);

    const NewAiData = () => {
      return {
        step: 0,
        type: null,
        description: null,
        AiResponses: [],
        selectedResponse: null,
        selectedIndex: null,
      };
    };

    const onImageClick = () => {
      //const target = e.target
      if (props.editing) {
        //target.parentNode.querySelector('.uploader-input').click()
        uploaderInput.value.click();
      }
    };
    const fileSelected = (event) => {
      files.value = event.target.files;
      if (files.value && files.value[0]) {
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
          currentFileUrl.value = e.target.result;
          emit("image-url-load", currentFileUrl.value);
        });
        reader.readAsDataURL(files.value[0]);
      }
      emit("image-selected", files.value);
    };

    const handleDallEResponseSelected = (response) => {
      if (response && response.content) {
        try {
          // Verifica se la stringa base64 è valida
          if (!/^data:image\/png;base64,/.test(response.content)) {
            throw new Error("Invalid base64 string");
          }
          const base64Data = response.content;
          currentFileUrl.value = base64Data;
          emit("image-url-load", currentFileUrl.value);
          emit("image-selected", base64Data);
        } catch (error) {
          console.error("Failed to decode base64 string:", error);
        }
      }
    };

    const deleteImage = () => {
      ImageAiData.value = NewAiData(); // clear cached AI data
      currentFileUrl.value = null;
      uploaderInput.value.value = ""; // Reset the file input value
      emit("image-deleted");
    };

    const OpenImageAi = () => {
      if (!ImageAiData.value) {
        ImageAiData.value = NewAiData();
      }
      if (ImageAiData.value.description === null) {
        ImageAiData.value.description = props.IaImageDescription;
      }
      showImageAi.value = true;
    };

    const CloseImageAi = () => {
      if (!ImageAiData.value.selectedResponse) {
        // clear cached data if process has been canceled
        ImageAiData.value = NewAiData();
      }
      showImageAi.value = false;
    };

    return {
      files,
      fileSelected,
      uploaderInput,
      onImageClick,
      currentFileUrl,
      currentImage,
      deleteImage,
      showImageAi,
      ImageAiData,
      OpenImageAi,
      CloseImageAi,
      handleDallEResponseSelected,
    };
  },
};
</script>
<style lang="postcss">
.uploader-input {
  opacity: 0;
}
.usercard-img {
  height: auto;
  cursor: pointer;
}
.uploader-txt,
.overlay-panel a.uploader-txt {
  line-height: 40px;
  text-align: center;
  background-color: #2cbeff;
  cursor: pointer;
  color: black;
}
.uploader-container {
  position: relative;
}
</style>
