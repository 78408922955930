<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            
            <div class="overlay-panel rel">
                <div class="flex-container flex-col p24">
                    <div class="panel-header ">
                        <div class="mini-logo"></div>
                         <h2 class="panel-title nomargin bebas">{{me.userName}}</h2>
                    </div>
                    <form class="content pb24 pt24 flex-container" ref="form" @submit.prevent>
                        <FileUpload
                            :editing="true"
                            :fileurl ="me?.avatar?.id ? API_BASE +'/file/'+ me.avatar.id : ''"
                            @image-selected="onImageSelected"
                        ></FileUpload>
                        <div class="flex-container flex-col pl16"> 
                            <div class="flex-container flex-col">
                            
                                <text-input
                                    :label="'username'"
                                    :value="me.userName"
                                    :editing="true"
                                    :placeholder="'inserisci nome Utente'"
                                    :name="'name'"
                                ></text-input>
                                <email-input 
                                    placeholder="email" 
                                    label="email" 
                                    :email="me.email" 
                                    :editing="false"
                                ></email-input>
                                
                                <user-role-select 
                                    :selected="me" 
                                    :editing="false" 
                                ></user-role-select>
                                <user-scope-select 
                                    v-if="me.userScope"
                                    :scopes="[me.userScope]" 
                                    :editing="false" 
                                    :selectedUser="me"  
                                ></user-scope-select> 
                                <text-input
                                    v-if="me?.userScope?.concessionaire"
                                    :label="'Concessionaire'"
                                    :value="me.userScope.concessionaire.name"
                                    :editing="false"
                                ></text-input>
                            </div>
                        </div>
                    </form>
                    
                </div>  
                <div class="flex-container abs panel-footer w100">
                        <button class="w100 deny noborder nopadding bebas" @click="this.$emit('close', message)">chiudi</button>
                        <button class="w100 noborder nopadding bebas" @click="save">salva </button>
                    </div>
            </div> 
         </div>
      
    </teleport>
</template>
<script>
import {inject, ref} from 'vue'
import {useStore} from 'vuex'
import UserScopeSelect from '@/components/user/UserScopeSelect'
import UserRoleSelect from '@/components/user/UserRoleSelect'
import EmailInput from '@/components/formelements/EmailInput'
import TextInput from '@/components/formelements/TextInput'
import FileUpload from '@/components/FileUpload'
import { API_BASE } from '@/api/Endpoints'
//import {getScopesForUser} from '@/utils/UserFunctions'
export default {
  components: {
    UserScopeSelect,
    UserRoleSelect,
    EmailInput,
    TextInput,
    FileUpload
  }, 
  emits: ['close'],
  setup(){
        const store = useStore()

        
        const me = inject('me')
        const avatar = ref(null)
        const onImageSelected =(files)=>{
            avatar.value = files[0]
        }
        const form =ref(null)
        const uploadAvatar = async() => {
           await store.dispatch('user/upload', {
                avatar:avatar.value, id:me.value.id
            })
            await store.dispatch('auth/me')
        } 
        
        const save = async () => {
            let hasAvatar = true
            if(avatar.value){
                hasAvatar = false
                try {
                    await uploadAvatar()
                    hasAvatar = true
                } catch (error) {
                    await store.dispatch('messages/message', {
                        title:"errore nell'upload dell'avatar",
                        message:error.message
                    })
                }
                
            }
            if(hasAvatar){
                
                const formData = new FormData(form.value)
                const data = {}
                for (let key of formData.keys()) {
                    data[key] = formData.get(key)
                }

                if(me.value.id){
                    try{
                        await store.dispatch('user/update', {
                            id:me.value.id,
                            data:data
                        })
                        await store.dispatch('messages/message', {
                            title: me.value.userName,
                            message:'Utente modificato correttamente' 
                        })
                    }catch(error){
                        await store.dispatch('messages/message', {
                            title:'errore nella modifica utente',
                            message:error.message
                        })
                    }
                    
                } 
                //store.commit('user/user_edit', {editing:false})
            }
            
        }

      return{
          me,
          onImageSelected,
          save,
          API_BASE,
          form
      }
  }
}
</script>
<style lang="postcss">

.overlay-panel button.deny {
    color: white;
    background-color: black;
}
</style>