<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
             <div class="overlay-panel rel">
                <div class="panel-header p24">
                    <div class="mini-logo"></div>
                    <h2 
                        class="panel-title nomargin"
                    >
                        {{title}}
                    </h2>
                </div>
                <p class="pr24 pl24">{{message}}</p>
                <div class="flex-container flex-container abs panel-footer w100">
                    <button class="w100 noborder nopadding bebas" @click="this.$emit('hide-modal', message)">Ok</button>
                </div>
            </div> 
        </div>
    </teleport>     
</template>
<script>

export default {
  props: {
    title: String,
    message:String
  }, 
  emits: ['hide-modal'], 
}
</script>