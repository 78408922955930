import axios from 'axios'
import { ApiEndPoints } from '../../api/Endpoints';
import { API } from '../../api/API';

/**
 * 
 * ======================================== ACTIONS
 */


const login = async ({commit}, user) => {
    commit('auth_request')
    try {
        const {expiration, token} = await API.post(ApiEndPoints.LOGIN, user)
        axios.defaults.headers.common['Authorization'] = 'bearer ' + token
        commit('auth_success', {token, expiration})
    } catch (error) {
        commit('auth_error')
        throw new Error(error.message)
    }
  }
  
  const forgotPassword = async ({commit}) => {
    commit('auth_forgot')
  } 
  const undoForgotPassword = async ({commit}) => {
    
    commit('auth_forgot_undo')
  } 

  const resetPassword = async ({commit}, {email}) => {
    
    try {
      await API.get(ApiEndPoints.PASSWORD_RESET+'/'+ email)
      commit('auth_forgot_success')
    } catch (error) {
        commit('auth_error')
        throw new Error(error.message)
    }
  }
  const changePassword = async ({commit}, {password, passwordConfirmation, token}) => {
    
    try {
      await API.post(ApiEndPoints.PASSWORD_RESET+'/'+ token,{password, passwordConfirmation} )
      commit('auth_forgot_success')
    } catch (error) {
        commit('auth_error')
        throw new Error(error.message)
    }
  }
  
  const dologout = async ({commit})=> {
    commit('logout')
    delete axios.defaults.headers.common['Authorization']
  }
  
  const me = async ({commit})=>{
    
    commit('identity_request')
    try {
      const {data} = await API.get(ApiEndPoints.USER_ME)
      commit('identity_success' ,{ data })
    } catch (error) {
      commit('identity_error')
    }
  }
  const alterEgo = async ({commit})=>{
    
    commit('identity_request')
    try {
      const {data} = await API.get(ApiEndPoints.USER_ME)
      commit('identity_switch_me_success' ,{ data })
    } catch (error) {
      commit('identity_error')
    }
  }
  
  const switchRole = async ({commit}, newRole)=>{
    commit('identity_switch_request', newRole )
    try {
      const {data} = await API.get(ApiEndPoints.MOCK+'/'+ newRole.id)
      commit('identity_switch_success' ,{ data })

    } catch (error) {
      commit('identity_switch_error')
    }
  }

export  const actions = {
    login,
    dologout,
    me,
    alterEgo,
    switchRole,
    forgotPassword,
    resetPassword,
    undoForgotPassword,
    changePassword
  }