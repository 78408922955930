import { mutations } from './mutations';
import { actions } from './actions';

/**
 * Module
 */

export const calendarModule = {
    state () {
        return {
          events:[],
          loadcount:0,
          loading:false
        }
    },
    namespaced: true,
    getters: {
        events: state => state.events,
        loading: state => state.loading,
        loadcount: state => state.loadcount,
    },
    mutations,
    actions
}