<template>
  <div :class="className">
    <div class="flex-container heading-and-error no-grow">
      <label v-if="label">
        <h3 class="bebas nomargin">{{ label }}</h3>
      </label>
    </div>
    <Multiselect
      v-if="config.mode === 'single'"
      :mode="config.mode"
      :trackBy="config.trackBy"
      :label="config.label"
      :valueProp="config.valueProp"
      placeholder="seleziona..."
      noOptionsText="nessun elemento trovato"
      :searchable="true"
      :createTag="false"
      :filterResults="false"
      :clearOnSearch="false"
      :options="
        async function () {
          return await getselectOptions();
        }
      "
      :object="true"
      :disabled="!editing"
      ref="multiselect"
      v-model="selectedValue"
      @select="onSelect"
      @deselect="onDeSelect"
      @open="onOpen"
      @close="onClose"
      @search-change="onSearch"
    >
    </Multiselect>
    <Multiselect
      v-else-if="config.mode === 'tags'"
      :mode="config.mode"
      :trackBy="config.trackBy"
      :label="config.label"
      :valueProp="config.valueProp"
      placeholder="seleziona..."
      noOptionsText="loading..."
      :searchable="true"
      :createTag="false"
      :filterResults="false"
      :clearOnSearch="false"
      :options="
        async function () {
          return await getselectOptions();
        }
      "
      :object="true"
      :disabled="!editing"
      ref="multiselect"
      v-model="selectedValue"
      @select="onSelect"
      @deselect="onDeSelect"
      @open="onOpen"
      @close="onClose"
      @search-change="onSearch"
    >
      <template v-slot:tag="{ option, handleTagRemove, disabled }">
        <span>
          <div class="multiselect-tag" @click.prevent="() => tagPress(option)">
            {{ option.name }}
            <i
              v-if="!disabled"
              @click.prevent
              @mousedown.prevent.stop="handleTagRemove(option, $event)"
            />
          </div>
        </span>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import { debounce } from "@/utils/TimeFunctions";
import { /* onMounted, */ ref, reactive } from "vue";
import Multiselect from "@vueform/multiselect";
import { API } from "@/api/API";
export default {
  emits: ["select", "deselect", "tag-press"],
  components: {
    Multiselect,
  },
  props: {
    editing: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "flex-container flex-col no-grow",
    },
    label: {
      type: String,
      default: null,
    },
    keysuffix: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Object,
      default: () => {},
    },
    tags: {
      type: Object,
      default: () => {},
    },
    feedUrl: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default() {
        return {
          mode: "single",
          trackBy: "name",
          label: "name",
          valueProp: "name",
        };
      },
    },
  },
  setup(props, { emit }) {
    const multiselect = ref(null);
    const selectedValue = ref(props.selected);
    const selectOptions = reactive({ items: [] });
    let pagination;

    const onSelect = (option) => {
      emit("select", option);
    };
    const onDeSelect = (option) => {
      //console.log(multiselect.value.mode)
      if (multiselect.value.mode === "single") {
        multiselect.value.clear();
        //emit('deselect', option, multiselect)
      } /* else{
                emit('deselect', option)
            } */
      emit("deselect", option);
      //emit('deselect' , props.allTags.find(t => t.id===tagId))
    };

    const getselectOptions = async () => {
      return selectOptions.items;
    };

    const fillOptions = (currentPage, data) => {
      if (currentPage === 1) {
        selectOptions.items = data;
      } else {
        data.forEach((el) => {
          const opt = selectOptions.items.find((opt) => opt.id === el.id);
          if (!opt) {
            selectOptions.items.push(el);
          }
        });
      }
    };

    const getPaginatedOptions = async (currentPage = 1, params = {}) => {
      const { data, headers } = await API.get(props.feedUrl, {
        ...props.params,
        ...params,
        PageNumber: currentPage || 1,
      });
      pagination = JSON.parse(headers["x-pagination"]);
      fillOptions(currentPage, data);

      try {
        if (panel) {
          panel.removeEventListener("scroll", onPanelScroll);
        }
        multiselect.value.refreshOptions();
        setTimeout(() => {
          setupScrollingPanel();
        }, 200);
      } catch (error) {
        console.log(error);
      }
    };

    const onOpen = async () => {
      if (selectOptions.items.length === 0) {
        await getPaginatedOptions();
      }
    };

    let panel;
    const onPanelScroll = async (e) => {
      const bottomReach =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1;
      const hasPagesToLoad = pagination.CurrentPage < pagination.TotalPages;
      if (bottomReach && hasPagesToLoad) {
        await getPaginatedOptions(pagination.CurrentPage + 1);
      }
    };

    const setupScrollingPanel = () => {
      panel = multiselect.value.$el.querySelector(".multiselect-options");
      panel.addEventListener("scroll", onPanelScroll);
    };

    const onClose = () => {
      selectOptions.items = [];
      if (panel) {
        panel.removeEventListener("scroll", onPanelScroll);
      }
    };

    const onSearch = debounce(async (query) => {
      if (multiselect?.value?.isOpen) {
        query !== ""
          ? await getPaginatedOptions(1, { search: query })
          : await getPaginatedOptions(1, {});
      }
    }, 250);

    const tagPress = (tag) => {
      emit("tag-press", tag);
    };

    return {
      selectedValue,
      selectOptions,
      multiselect,
      onSelect,
      onDeSelect,
      onOpen,
      onClose,
      onSearch,
      debounce,
      getselectOptions,
      tagPress,
    };
  },
};
</script>
<style lang="postcss">
.multiselect-single-label,
.multiselect-multiple-label,
.multiselect-placeholder {
  padding-left: 0;
}
.multiselect-option.is-selected {
  background-color: #2cbeff;
  &.is-pointed {
    background-color: #2cbeff;
  }
}
.is-tags .multiselect-search input {
  background-color: #ffffff;
}
</style>
