import { mutations } from './mutations';
import { actions } from './actions';

export const authModule = {
    state () {
        return {
          status: '',
          token:  '',
          realToken:'',
          expiration: '',
          me: null,
          currentMe:null,
          pwdForgot:false,
          realMe:null,
          mocking:false
        }
    },
    namespaced: true,
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        token: state => state.token,
        expiration:state => state.expiration,
        me: state => state.me,
        realRole: state => state.me?.roles[0],
        currentMe: state => state.currentMe,
        realMe: state => state.realMe,
        pwdForgot:state => state.pwdForgot,
        mocking:state => state.mocking
    },
    mutations,
    actions
}