import { mutations } from './mutations';
import { actions } from './actions';




export const messagesModule = {
    state () {
        return {
            title: null,
            message: null,
            show:false
        }
    },
    namespaced: true,
    getters: {
        message: state => state.message,
        show: state => state.show,
        title: state => state.title

    },
    mutations,
    actions
}