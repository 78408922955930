import { API } from '@/api/API';
import { ApiEndPoints } from '@/api/Endpoints'


const index = async({commit})=>{
    commit('request')
    try {
        const {data} = await API.get(ApiEndPoints.GOOGLE_CALENDAR)
        commit('get_success' ,{ data })
        return data
    } catch (error) {
        commit('get_error')
        throw new Error(error.message)
    }
}
const queryDates = async({commit},params)=>{
    
    commit('request')
    try {
        const {data} = await API.get(ApiEndPoints.GOOGLE_CALENDAR, params)
        commit('get_success' ,{ data })
        return data
    } catch (error) {
        commit('get_error')
        throw new Error(error.message)
    }
}

const publishDate = async({commit}, payload)=>{
    commit('request')
    try {
        const data = await API.post(ApiEndPoints.GOOGLE_CALENDAR, payload)
        commit('publishdate_success' ,{ data })
        return data
    } catch (error) {
        commit('publishdate_error')
        throw new Error(error.message)
    }
}

const deleteEvent = async({commit}, {id}) => {
    commit('request')
    try {
        const data = await API.delete(ApiEndPoints.GOOGLE_CALENDAR+'/' + id)
        commit('delete_success' )
        return data
    } catch (error) {
        commit('get_error')
        throw new Error(error.message)
    }
}

export const actions = {
    index,
    publishDate,
    deleteEvent,
    queryDates
}