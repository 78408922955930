import { API } from '@/api/API';
import { ApiEndPoints } from '@/api/Endpoints'
import {useCapabilities} from '@/utils/Capabilities'
//import axios from 'axios'
//import jQuery from 'jQuery'

const index = async({commit})=>{
    commit('request')
    try {
        const {data} = await API.get(ApiEndPoints.POST_FB)
        commit('get_success' ,{ data })
        return data
    } catch (error) {
        commit('get_error')
        throw new Error(error.message)
    }
}

const facebookSearch = async({commit},payload)=>{
  const {socialId,data} = payload
  const params = data ? data : {}
  commit('request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/social/facebook' : ApiEndPoints.POST_FB
    const response = await API.post(endpoint+'/'+ socialId+'/search', params)
    commit('facebook_search_success', response) 
    return response
  } catch (error) {
    commit('facebook_error')
    if(error.message.startsWith('Invalid OAuth access token.') || error.message.startsWith('(#200) Provide valid app ID') ) {
      const socialName =  error.message.split('|')[2]
      commit('reset/facebook_reset_oauth', {socialId, socialName},{ root: true })
    }
    throw new Error(error.message)
  }
}

const facebookAssignPost = async({commit},data)=>{
  commit('request')
  try {
    
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/post/facebook' : ApiEndPoints.POST_FB
    
    const response = await API.post(endpoint, data) 
    commit('facebook_assign_success', response) 
    return response
  } catch (error) {
    
    commit('facebook_error')
    throw new Error(error.message)
  }
}

const removeFacebookPost = async({commit}, postId)=>{
  commit('request')
  try {
    const { isPublisher, isConcessionaire} = useCapabilities()
    const usePublisherController = isPublisher()||isConcessionaire()
    const endpoint = usePublisherController ? ApiEndPoints.PUBLISHERS+'/post/facebook' : ApiEndPoints.POST_FB
    await API.delete(endpoint+'/'+ postId )
    //await API.delete(ApiEndPoints.POST_FB+'/'+ postId )
    commit('remove_success' ,{postId})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}

const createFacebookAdvertisment = async({commit},payload)=>{
    commit('request')
    try {
      const response = await API.post(ApiEndPoints.POST_FB_ADVERT, payload)
      commit('facebook_advert_create_success', response) 
      return response
    } catch (error) {
      commit('facebook_error')
      throw new Error(error.message)
    }
}

const insertFacebookAdvPost = async({commit},payload)=>{
    //alert('post')
    //alert(payload.postId)


      //const response2 = await API.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
      // alert(response2.toString())
       //alert('ciao') 

     // const a = axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
     // .then(response2 => alert(response2.toString()))

/*
      // create a promise for the axios request
    const promise = axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) =>{ alert(response.data)})

    //alert(dataPromise.toString())
    console.log(dataPromise);
   // alert(JSON.parse(dataPromise));

   */
   

   


    /*
   const { data } = await axios({
    url:
    "https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul",
    method: "get",
  });
  console.log("response from fb request", data);*/
    

/*
    axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
  .then(response => {
    alert("Source of Anime Qoutes: \n\n\n" + JSON.parse(response.data));
  });
  */
 

  

/*
  axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
                .then(response => {
                    console.log('success');
                    console.log(response);
                })
                .catch(err => {
                    console.log(err.response.data.error.message);
                    var st = err.response.data.error.message;
                    alert(err.response.data.error.message);
                    var ind1 = st.indexOf("_")
                    var ind2 = st.indexOf("'",ind1)
                    var substr = st.substring(ind1+1 , ind2);
                    alert(substr)
                    postrotto(substr)
                    //console.log(err.status);
                    //console.log(err.response.status)
                });

function postrotto(substr){
axios.get("https://graph.facebook.com/v17.0/Repubblica?access_token=EAAWZAuERZCpeQBACG5Fl5rXcWrGMEMPhW68a8b1g9HucTwc3BRZBtzyLOD5m85dRs5qWiLnPzNZCl93inqcuAiHa9UXAs4noPduuivNMsLaZAuALS6IfS9ZA2ZAam8yf9hjmdmsbOpMbG8vicECoT49U61GMZCpGq1MgdOUYzlJdXgZDZD")
                .then(response => {
                    console.log('success');
                    console.log(response);
                    alert(response.data.id)
                    alert(payload.postId);
                    payload.postId = response.data.id+"_"+substr;
                    alert(payload.postId);


                    
                    

                })
                .catch(err => {
                    console.log(err.response.data.error.message);
            
                    //alert(err.response.data.error.message);

                  
                  
                    //console.log(err.status);
                    //console.log(err.response.status)
                });

              }
      
*/
                //axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul").then(response => {
              //   var results = response.data
              //   alert(results.toString())
              //    alert('ciao')
                // results.forEach(function(entry) {
                //   alert(entry);
                // });
            // })
              
   commit('request')
    
    try {

      //axios.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
      //.then(response2 => alert(response2))
  

       //const response2 = await API.get("https://graph.facebook.com/v17.0/Repubblica_pfbid0bC6MetsAr958vtg9Hb7eZJr4vjUgEnEJizs61n17GaBUk4CWv1ru2eswPDzTkA7ul")
       //alert(response2.toString())
       //alert('ciao') 




    
      const response = await API.post(ApiEndPoints.POST_FB_ADVERT+'/'+ payload.facebookAdId+'/advertisement', payload)
      commit('facebook_advert_post_assign_success', response) 
      return response
    } catch (error) {
      commit('facebook_error')
      if(error.message.startsWith('Invalid OAuth access token.') || error.message.startsWith('(#200) Provide valid app ID')) {
        commit('reset/facebook_reset_oauth', payload.socialId,{ root: true })
      }
      throw new Error(error.message)
    }
}

const removeFacebookAdvertisment = async({commit}, id)=>{  
  commit('request')
  try {
    await API.delete(ApiEndPoints.POST_FB_ADVERT+'/'+ id )
    commit('remove_success_advert' ,{id})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}


export const actions = {
    index,
    facebookSearch,
    facebookAssignPost,
    removeFacebookPost,
    createFacebookAdvertisment,
    removeFacebookAdvertisment,
    insertFacebookAdvPost
}