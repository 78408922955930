const Tags = () => import(/* webpackChunkName: "tags" */ '@/views/Tags.vue')
import {store} from '@/store/store.js'
import {resetResizerValues} from '@/utils/ColumnsResizer' 
export const tags ={
    path: '/tags',
    name: 'tags',
    component: Tags,
    meta: {
        requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
        try {
        await store.dispatch('tags/getTags')
        resetResizerValues('tags')
        store.commit('tags/clear_filters')
        next();
        } catch(exception) {
        next(exception);
        }
    }
}

export const tagById = {
    path: '/tag/:id',
    name: 'tag',
    component: Tags,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {  
        if(store.getters['tags/tags'].length === 0){
          await store.dispatch('tags/getTags')
        }
        
        resetResizerValues('tags')
        store.commit('tags/clear_filters')
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }