/**
 * User roles
 */
export const USER_ROLE_SUPER = "Superuser";
export const USER_ROLE_ADMIN = "Admin";
export const USER_ROLE_ADVERTISER = "Advertiser";
export const USER_ROLE_USER = "User";
export const USER_ROLE_CONCESSIONAIRE_ADMIN = "ConcessionaireAdmin";
export const USER_ROLE_CONCESSIONAIRE_USER = "ConcessionaireUser";
export const USER_ROLE_PUBLISHER_ADMIN = "PublisherAdmin";
export const USER_ROLE_PUBLISHER_USER = "PublisherUser";

/**
 * Policies
 */
export const POLICY_MANAGE_USERS = "ManageUsers";
export const POLICY_MANAGE_USERSCOPE = "ManageUserScope";
export const POLICY_MANAGE_TAG = "ManageTag";
export const POLICY_MANAGE_CAMPAIGN = "ManageCampaign";
export const POLICY_MANAGE_SOCIAL = "ManageSocial";
export const POLICY_SAMEUSER = "SameUser";
export const POLICY_OWNSSOCIAL = "OwnsSocial";
export const POLICY_MANAGE_QUOTES = "ManageQuotes";

/**
 * userScope: Concessionaire/Publisher
 */
export const USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR = "Concessionaire";
export const USER_SCOPE_PUBLISHER_DISCRIMINATOR = "Publisher";

export const SuperUser = {
  name: USER_ROLE_SUPER,
  policies: [
    POLICY_MANAGE_USERS,
    POLICY_MANAGE_USERSCOPE,
    POLICY_MANAGE_TAG,
    POLICY_MANAGE_CAMPAIGN,
    POLICY_MANAGE_SOCIAL,
    POLICY_SAMEUSER,
    POLICY_OWNSSOCIAL,
    POLICY_MANAGE_QUOTES,
  ],
};
export const AdminUser = {
  name: USER_ROLE_ADMIN,
  policies: [
    POLICY_MANAGE_USERS,
    POLICY_MANAGE_USERSCOPE,
    POLICY_MANAGE_TAG,
    POLICY_MANAGE_CAMPAIGN,
    POLICY_MANAGE_SOCIAL,
    POLICY_SAMEUSER,
    POLICY_OWNSSOCIAL,
    POLICY_MANAGE_QUOTES,
  ],
};
export const AdvertiserUser = {
  name: USER_ROLE_ADVERTISER,
  policies: [
    POLICY_MANAGE_CAMPAIGN,
    POLICY_MANAGE_SOCIAL,
    POLICY_SAMEUSER,
    POLICY_OWNSSOCIAL,
  ],
};

export const ConcessionaireAdmin = {
  name: USER_ROLE_CONCESSIONAIRE_ADMIN,
  policies: [
    POLICY_MANAGE_USERS,
    POLICY_MANAGE_USERSCOPE,
    POLICY_MANAGE_CAMPAIGN,
    POLICY_MANAGE_SOCIAL,
    POLICY_SAMEUSER,
    POLICY_OWNSSOCIAL,
  ],
  discriminator: USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR,
};
export const ConcessionaireUser = {
  name: USER_ROLE_CONCESSIONAIRE_USER,
  policies: [POLICY_MANAGE_CAMPAIGN, POLICY_SAMEUSER],
  discriminator: USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR,
};
export const PublisherAdmin = {
  name: USER_ROLE_PUBLISHER_ADMIN,
  policies: [
    POLICY_MANAGE_USERS,
    /* POLICY_MANAGE_USERSCOPE, */
    POLICY_MANAGE_CAMPAIGN,
    POLICY_MANAGE_SOCIAL,
    POLICY_SAMEUSER,
    POLICY_OWNSSOCIAL,
  ],
  discriminator: USER_SCOPE_PUBLISHER_DISCRIMINATOR,
};
export const PublisherUser = {
  name: USER_ROLE_PUBLISHER_USER,
  policies: [POLICY_MANAGE_SOCIAL, POLICY_SAMEUSER, POLICY_MANAGE_CAMPAIGN],
  discriminator: USER_SCOPE_PUBLISHER_DISCRIMINATOR,
};

export const Roles = [
  SuperUser,
  AdminUser,
  AdvertiserUser,
  PublisherAdmin,
  PublisherUser,
  ConcessionaireAdmin,
  ConcessionaireUser,
];

export const getPoliciesByRoleName = (role) => {
  return Roles.find((r) => r.name === role).policies;
};
