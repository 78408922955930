<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel pb24">
        <form
          class="flex-container flex-col min-height-inherit"
          id="panel"
          @submit.prevent="onSubmit"
        >
          <div class="panel-header p24">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Genera Immagine</h2>
          </div>

          <keep-alive>
            <div
              v-if="data.step === 0"
              class="flex-container flex-col pl24 pr24 pb24"
            >
              <textarea
                class="flex-container flex-col add-description pb16"
                :value="data.description || ''"
                @input="onDescriptionInput"
                placeholder="descrivi il contenuto dell'immagine da generare"
                maxlength="4000"
              ></textarea>
            </div>
          </keep-alive>

          <keep-alive>
            <div
              v-if="data.step === 1"
              class="flex-container flex-row pl24 pr24 pb24"
              id="cody-response-container"
            >
              <div
                v-if="data.isLoading"
                class="flex-container flex-end-align spinner-container"
              >
                <Spinner id="spinner" />
              </div>
              <div v-else id="cody_response_container_list">
                <div v-if="data.AiResponses && data.AiResponses.length > 0">
                  <div
                    v-for="(response, index) in data.AiResponses"
                    :key="index"
                    :class="{ 'ai-selected': index === data.selectedIndex }"
                    @click="selectResponse(response, index)"
                    class="innovativestudio_AiResponse_element pnt"
                  >
                    <p>{{ response.revised_prompt }}</p>
                    <img
                      :src="response.content"
                      alt="Anteprima"
                      class="ai-response-preview"
                    />
                  </div>
                </div>
                <div v-else>
                  <p>Nessun dato disponibile</p>
                </div>
              </div>
            </div>
          </keep-alive>

          <div class="flex-container panel-footer abs w100">
            <div class="flex-container">
              <input
                type="reset"
                :value="data.step === 0 ? 'annulla' : 'indietro'"
                class="w100 noborder nopadding bebas"
                @click.prevent="prev"
              />
              <input
                type="submit"
                :value="nextButtonText"
                class="w100 noborder nopadding bebas"
                :disabled="preventNext"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, reactive, ref, inject, watch } from "vue";
import { ApiEndPoints } from "@/api/Endpoints";
import axios from "axios";
import Spinner from "@/components/Spinner.vue";

export default {
  props: ["AiData"],
  methods: {
    selectResponse(response, index) {
      this.data.selectedResponse = response;
      this.data.selectedIndex = index;
    },
  },
  emits: ["close", "response-selected", "aidata-updated"],
  components: {
    Spinner,
  },
  setup(props, { emit }) {
    //const store = useStore();
    const me = inject("me");
    const type = ref(null);

    const startCal = ref(null);

    const data = reactive({
      step: props.AiData?.step || 0,
      type: props.AiData?.type || null,
      description: props.AiData?.description || "",
      selectedResponse: props.AiData?.selectedResponse || null,
      selectedIndex: props.AiData?.selectedIndex || null,
      AiResponses: props.AiData?.AiResponses || [],
      isLoading: false,
    });

    watch(
      () => props.AiData,
      (newVal) => {
        data.step = newVal.step || 0;
        data.type = newVal.type || null;
        data.description = newVal.description || "";
        data.selectedResponse = newVal.selectedResponse || null;
        data.selectedIndex = newVal.selectedIndex || null;
        data.AiResponses = newVal.AiResponses || [];
      },
      { immediate: true, deep: true }
    );

    const preventNext = computed(() => {
      if (data.step === 0 && data.description?.length > 0) {
        return false;
      }
      if (data.step === 1 && data.selectedResponse) {
        return false;
      }

      return true;
    });

    const nextButtonText = computed(() =>
      data.step === 1 ? "conferma" : "genera"
    );

    const next = () => {
      data.step++;
      if (data.step === 1) {
        GenerateContent();
      }
    };
    const prev = () => {
      if (data.step === 0) {
        emit("close");
      } else {
        data.step--;
      }
    };

    const onDescriptionInput = (e) => {
      if (e.target.value.length <= 4000) {
        data.description = e.target.value;
      } else {
        data.description = e.target.value.substring(0, 4000);
      }
    };

    const onSubmit = async () => {
      if (data.step === 1) {
        confirm();
      } else {
        next();
      }
    };

    const GetAiResponses = async (amount) => {
      try {
        // Limita l'amount a 10
        if (amount > 10) amount = 10;

        let response = await axios.post(ApiEndPoints.AI + "/createimage", {
          n: amount,
          prompt: data.description,
        });

        // "response" contiene la risposta di OpenAI e potrebbe contenere più immagini.
        let AiResponses = response.data.data.map((imageData) => {
          return {
            error: false,
            content: `data:image/png;base64,${imageData.b64_json}`,
            revised_prompt: imageData.revised_prompt,
          };
        });

        return AiResponses;
      } catch (error) {
        // Gestisci l'errore qui
        return [{ error: true, message: error.message }];
      }
    };

    const GenerateContent = async () => {
      // reset
      data.selectedResponse = null;
      data.selectedIndex = null;
      // restart query
      data.isLoading = true;
      try {
        // Chiamata al backend
        const AiResponses = await GetAiResponses(1);
        data.AiResponses = AiResponses;
      } catch (error) {
        console.error(error);
      } finally {
        data.isLoading = false;
      }
    };

    const confirm = () => {
      if (data.selectedResponse) {
        // Crea una copia dei nuovi dati da inviare
        const newData = { ...data };
        newData.step = 1;

        // Emetto l'evento con i nuovi dati generati al padre
        emit("aidata-updated", newData);

        // Restituisci il testo selezionato al componente padre
        //this.$emit("response-selected", data.selectedResponse);
        emit("response-selected", data.selectedResponse); // invia il messaggio al componente padre
        emit("close");
      }
    };

    return {
      me,
      emit,
      onSubmit,
      preventNext,
      next,
      prev,
      data,
      type,
      nextButtonText,
      onDescriptionInput,
      startCal,
      ApiEndPoints,
    };
  },
};
</script>

<style lang="postcss">
.flex-container {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.ai-selected {
  background: #fffbe0;
}

.ai-response-preview {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.error-message {
  color: red;
}
</style>
