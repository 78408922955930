import axios from 'axios'
import {getPoliciesByRoleName} from '../../utils/RolesAndPolicies'
/**
 * 
 * ======================================== MUTATIONS
 */

const auth_request = (state) => {
    state.status = 'loading'
  }
  const auth_forgot = (state) => {
    state.pwdForgot = true
  }
  const auth_forgot_undo = (state)=> {
    state.pwdForgot = false
  }
  const auth_forgot_success = (state)=> {
    state.pwdForgot = false
  }
  
  const auth_success = (state, {token, expiration})=> {
    state.status = 'logged'
    state.token = token
    state.realToken = token
    state.expiration = expiration
    state.pwdForgot = false
  }
  
  const auth_error = (state)=> {
    state.status = 'logerror'
  }
  
  const logout = (state) => {
    state.status = ''
    state.token = null
    state.expiration = ''
    state.pwdForgot = false,
    state.me = null
  }
  
  const identity_request = (state)=>{
    state.loading = true
  }
  
  const identity_success = (state, {data})=>{
    /* console.log('id success')
    console.log(data) */
    state.loading = false
    state.me = data
    state.realMe = data
    state.mocking = false
    const lvl = data.roles[0]
    state.currentMe = data
    state.currentMe.policies = getPoliciesByRoleName(lvl)
    state.realMe.policies = getPoliciesByRoleName(lvl)
  
    state.currentRole = {
      loglevel:lvl,
      policies:getPoliciesByRoleName(lvl)
    }
  }
  
  const identity_switch=(state, {newRole})=>{
    state.currentRole = {
      loglevel:newRole.role,
      policies:getPoliciesByRoleName(newRole.role)
    }
  }
  const identity_switch_request=(state, user)=>{
    state.loading = true
    state.me = user
    
  }
  const identity_switch_success=(state, {data})=>{
    state.loading = false
    state.mocking = true
    state.token = data.token
    axios.defaults.headers.common['Authorization'] = 'bearer ' + data.token
    state.token = data.token 
  }

  const identity_switch_me_success =(state, {data}) => {
    state.currentMe = data
    const lvl = state.currentMe.roles[0]
    state.currentMe.policies = getPoliciesByRoleName(lvl)
  }

  const identity_switch_reset =(state) =>{
    state.loading = false
    state.me = state.realMe
    state.token = state.realToken
    
    state.mocking = false
    
    const lvl = state.realMe.roles[0]
    
    state.currentMe = state.realMe
    
    state.currentRole = {
      loglevel:lvl,
      policies:getPoliciesByRoleName(lvl)
    }
    axios.defaults.headers.common['Authorization'] = 'bearer ' + state.token
  } 
  const identity_switch_error =(state)=>{
    state.loading = false
    state.me = state.realMe
  }
  const identity_error =(state)=>{
    state.loading = false
    state.me = null
  }
  
  
  
export  const mutations = {
    auth_request,
    auth_success,
    auth_error,
    auth_forgot,
    auth_forgot_undo,
    auth_forgot_success,
    logout,
    identity_request,
    identity_success,
    identity_error,
    identity_switch,
    identity_switch_request,
    identity_switch_success,
    identity_switch_error,
    identity_switch_reset,
    identity_switch_me_success
  }