import { ApiEndPoints } from '@/api/Endpoints';
import { API } from '@/api/API';

export const show = async (id) =>{
    const {data} = await API.get(ApiEndPoints.USER_SCOPE+'/'+id)
    return data
}



export const list = async (params = {}, endpoint = ApiEndPoints.USER_SCOPE) => {
    console.log('list')
    const {data, headers} = await API.get(endpoint, params)
    const pagination = JSON.parse(headers['x-pagination'])
    return {data, pagination}
}


export const apiUserScopeFunction = {
    show,
    list
} 