const request = (state)=>{
    state.loading = true
}
const get_success = (state, {data})=>{
    state.loading = false
    state.posts = data
}
const get_error =(state)=>{
    state.loading = false
    state.posts = []
}
const facebook_error =(state)=>{
    state.loading = false
    state.searchResult = []
}

const facebook_search_success=(state, {data})=>{
    
    state.loading = false
    state.searchResult = data
}
const facebook_assign_success=(state, response)=>{
    
    state.loading = false
    state.posts.unshift(response)

    //state.searchResult = data
}
const facebook_advert_create_success= (state, response)=>{
    state.loading = false
    state.adverts.unshift(response)
}
const facebook_advert_post_assign_success= (state, response)=>{
    state.loading = false
    state.adverts.unshift(response)
}

const remove_error =(state)=>{
    state.loading = false
  }
  const remove_success =(state, {postId})=>{
    state.loading = false
    const newPosts = state.posts.filter(p => p.postId !== postId)
    state.posts = newPosts
  }
  const remove_success_advert =(state, {id})=>{
    state.loading = false
    const newAdverts = state.adverts.filter(a => a.id !== id)
    state.adverts = newAdverts
  }

export const mutations = {
    request,
    get_success,
    get_error,
    facebook_search_success,
    facebook_error,
    
    facebook_assign_success,
    remove_error,
    remove_success,
    facebook_advert_create_success,
    facebook_advert_post_assign_success,
    remove_success_advert
}