import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import axios from 'axios'

import '@vueform/multiselect/themes/default.css'
import 'vue-cal/dist/vuecal.css'
import '@/css/style.css'
import '@/css/addons.css'
import '@/css/LineIcons.css'
import mitt from "mitt";
const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.http = axios
app.use(store)
app.use(router)
app.mount('#app')




