export const API_BASE = process.env.VUE_APP_API_BASE;
export const ApiEndPoints = {
  LOGIN: API_BASE + "/auth/login",
  MOCK: API_BASE + "/auth/mock",
  PASSWORD_RESET: API_BASE + "/auth/password/reset",
  USER_ME: API_BASE + "/user/me",
  USERS: API_BASE + "/user",
  USER_SCOPE: API_BASE + "/user-scope",
  CAMPAIGNS: API_BASE + "/campaign",
  TAGS: API_BASE + "/tag",
  SOCIAL_FB: API_BASE + "/social/facebook",
  SOCIAL_IG: API_BASE + "/social/instagram",
  SOCIAL: API_BASE + "/social",
  POST_FB: API_BASE + "/post/facebook",
  POST_FB_ADVERT: API_BASE + "/post/facebook-advertisement",
  SOCIAL_CAMPAIGNS: API_BASE + "/social-campaign",
  GOOGLE_CALENDAR: API_BASE + "/google/calendar/events",
  PUBLISHERS: API_BASE + "/publishers",
  PUBLISHERS_TEST: API_BASE + "/publishers/test",
  FACEBOOK_HELPER: API_BASE + "/helper/facebook",
  ADVERTISERS: API_BASE + "/advertisers",
  QUOTES: API_BASE + "/quote",
  AI: API_BASE + "/ai",
};
