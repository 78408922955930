import {store} from '@/store/store.js'
import {resetResizerValues} from '@/utils/ColumnsResizer' 
//import {useCapabilities} from '@/utils/Capabilities'
const Users = () => import(/* webpackChunkName: "users" */ '@/views/Users.vue')
export const users = {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
       // const { canManageScopes} = useCapabilities()
        await store.dispatch('user/users')
                
        /* if(canManageScopes.value){
         // await store.dispatch('userscope/userScopes')
        } */
        resetResizerValues('user')
        store.commit('user/clear_filters')
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }

export const userById = { 
    path: '/user/:id',
    name:'user',
    component: Users, 
    meta: {
      requiresAuth: true
    },
    beforeEnter: async(to, from, next) => {
      try {
        if(store.getters['user/users'].length === 0){
          await store.dispatch('user/users')
        }
        
        resetResizerValues('user')
        store.commit('user/clear_filters')
        next();
      } catch(exception) {
        next(exception);
      }
    }
  }