import {diffDates} from '@/utils/DateTimeUtils';

const request = (state)=>{
    state.loading = true
}
const get_success = (state, {data})=>{
    state.loading = false
    //state.events = data
    state.events = data.items.map(item => {

        const startDate = new Date(item.start.dateTime)
        const endDate = new Date(item.end.dateTime)
        const isAllDay = diffDates(startDate, endDate)
        return {
            id:item.id,
            start: startDate,
            end:endDate ,
            title: item.summary || 'no description',
            allDay:isAllDay,
            extendedProperties:item.extendedProperties
        }
    } )
    state.loadcount ++
    
}
const get_error =(state)=>{
    state.loading = false
    state.events = []
}

const publishdate_error =(state)=>{
    console.log('pub date error')
    state.loading = false
    
}

const publishdate_success = (state) => {   
    state.loading = false
}
const delete_success = (state) => {
    state.loading = false
    
}

export const mutations = {
    request,
    get_success,
    get_error,
    publishdate_success,
    publishdate_error,
    delete_success    
}