import { ApiEndPoints } from "../../api/Endpoints";
import { API } from "@/api/API";

const getTags = async ({ commit }, payload = {}) => {
  commit("tags_request");
  try {
    const { data, headers } = await API.get(ApiEndPoints.TAGS, payload);
    const pagination = JSON.parse(headers["x-pagination"]);
    commit("tags_success", { data, pagination });
    return data;
  } catch (error) {
    commit("tags_error");
    throw new Error(error.message);
  }
};
const getSingleTag = async ({ commit }, id) => {
  commit("tags_request");
  try {
    const { data /* , headers */ } = await API.get(
      ApiEndPoints.TAGS + "/" + id
    );
    //const pagination = JSON.parse(headers['x-pagination'])
    commit("tag_single_success", { data });
    return data;
  } catch (error) {
    commit("tags_error");
    throw new Error(error.message);
  }
};

const createTag = async ({ commit }, { tagName }) => {
  commit("tags_request");
  try {
    const data = await API.post(ApiEndPoints.TAGS, { name: tagName.value });
    commit("tags_save_success", { data });
    return data;
  } catch (error) {
    commit("tags_error");
    throw new Error(error.message);
  }
};
const clearList = async ({ commit }) => {
  commit("tags_clear");
};

const remove = async ({ commit }, id) => {
  commit("tags_request");
  try {
    await API.delete(ApiEndPoints.TAGS + "/" + id);
    commit("remove_success", { id });
  } catch (error) {
    commit("remove_error");
    throw new Error(error.message);
  }
};

const filter = async ({ commit }, filter) => {
  commit("tags_request");
  const params = {};

  if (filter.value !== "") {
    params[filter.type] = filter.value;
    try {
      const { data, headers } = await API.get(ApiEndPoints.TAGS, params);
      const pagination = JSON.parse(headers["x-pagination"]);
      //commit('tags_success' ,{ data, pagination })
      commit("do_filter", { data, pagination });
      return data;
    } catch (error) {
      commit("tags_error");
      throw new Error(error.message);
    }
  } else {
    commit("empty_filter");
    try {
      const { data, headers } = await API.get(ApiEndPoints.TAGS, {});
      const pagination = JSON.parse(headers["x-pagination"]);
      commit("tags_success", { data, pagination });
      return data;
    } catch (error) {
      commit("tags_error");
      throw new Error(error.message);
    }
  }
};
const sort = async ({ commit }, sorting) => {
  commit("do_sort", { sorting });
};

const update = async ({ commit }, tag) => {
  commit("tags_request");
  try {
    const data = await API.put(ApiEndPoints.TAGS + "/" + tag.id, {
      name: tag.name,
      id: tag.id,
      description: tag.description,
    });
    commit("tags_update_success", { tag });
    return data;
  } catch (error) {
    commit("tags_error");
    throw new Error(error.message);
  }
};

export const actions = {
  getTags,
  createTag,
  clearList,
  remove,
  filter,
  sort,
  update,
  getSingleTag,
};
