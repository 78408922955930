import { mutations } from './mutations';
import { actions } from './actions';

/**
 * Module
 */

export const tagsModule = {
    state () {
        return {
          tags:[],
          filtered:null,
          columnsSizes:null,
          pagination:null,
          current:null,
          visitedPages:[]
        }
    },
    namespaced: true,
    getters: {
        tags: state => state.tags,
        paged: state => {

            const currentPage = state.pagination.CurrentPage
            const pageSize = state.pagination.PageSize
            const offset = (currentPage * pageSize) - pageSize
            return state.tags.filter((tag, index) => (index >= offset && index <= offset + pageSize -1))
        },
        loading: state => state.loading,
        filtered:state=>state.filtered,
        columnsSizes:state=>state.columnsSizes,
        pagination:state=>state.pagination,
        current:state=>state.current,
    },
    mutations,
    actions
}