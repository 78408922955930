const request = (state)=>{
    state.loading = true
  }
const proposed_success = (state, {data, pagination})=>{
    state.loading = false
    state.pagination = pagination
    state.proposed = data
  }
  const get_error =(state)=>{
    state.loading = false
    state.proposed = []
  }
  export const mutations = {
      proposed_success,
      request,
      get_error
  }