const request = (state)=>{
    state.loading = true
  }
  /**
   * 
   * @param {*} state  Object 
   * @param {payload} Object contenente Array di campagne e Obj pagination
   *  
   */
  const get_success = (state, {data, pagination})=>{
    state.loading = false
    const {CurrentPage} = pagination 
    //prima navigazione
    if(!state.pagination){
      state.pagination = pagination
      state.campaigns = data
      state.visitedPages.push(CurrentPage)
    } else {
      ///se non ho mai visto la paginata -> aggiungo i nuovi dati
      if(!state.visitedPages.find(p => p===CurrentPage)){
        state.visitedPages.push(CurrentPage)
        state.campaigns = state.campaigns.concat(data)

      }else{
        ////altrimenti eseguo l'update sulla porzione di store
        data.forEach((campaign, indx) =>{
          const toReplace = state.campaigns.find(c =>c.id === campaign.id)
          if(toReplace){
            Object.assign(toReplace, campaign)
          }else {
            state.campaigns[indx] = campaign
          }
        })
      }
      state.pagination = pagination
    }

  }
  
  const get_error =(state)=>{
    state.loading = false
    state.campaigns = []
  }
  //campaigns_by_tag_success
  const by_tag_success = (state, {data}) =>{
    state.campaigns = data 
  }
  
  const clear =(state)=>{
    state.campaigns = []
    state.filtered=null,
    state.columnsSizes=null,
    state.facebookPages=[],
    state.analyticsPages=[],
    state.pagination={
      CurrentPage:1,
      PageSize:20
    },
    state.single=null,
    state.visitedPages=[]
  }


  const save_request = (state)=>{
    state.loading = true
  }
  const save_error =(state)=>{
    state.loading = false
  }
 
  const save_success = (state, response)=>{
    state.loading = false
    state.campaigns.unshift(response)
  }
  const update_success = (state, response)=>{
    state.loading = false
    const updated = state.campaigns.find(c=> c.id===response.id)
    Object.assign(updated, response)
  }
  const remove_error =(state)=>{
    state.loading = false
  }
  const remove_success =(state, campaign)=>{
    state.loading = false
    const newCampaigns = state.campaigns.filter(c => c.id !== campaign.id)
    state.campaigns = newCampaigns
  }

  const tag_update_error=(state)=>{
    
    state.loading = false
  }
  const tag_update_success = (state, {id,storetags})=>{
    const campaign = state.campaigns.find(c => c.id === id)
    Object.assign(campaign.campaignTags, storetags);
  }


  const social_sync_success = (state, response) => {
    const campaign = state?.campaigns.find(c => c.id === response.campaignId)
    if(campaign.socialCampaigns){
      const social = campaign?.socialCampaigns.find(s => s.socialId === response.socialId) || {}
      Object.assign(social, response);
    } else {
      campaign.socialCampaigns = []
      campaign.socialCampaigns.push(response)
    }
    
  }
  const social_sync_error = (state) => {
    state.loading = false
  }

  const social_remove_success = (state, {campaignId, socialId}) => {
    const campaign = state?.campaigns.find(c => c.id === campaignId)
    if(campaign.socialCampaigns){
      campaign.socialCampaigns = campaign?.socialCampaigns.filter(s => s.socialId !== socialId) || []
    }
    
  }


  const savecolumnsizes = (state, sizes)=>{
    state.columnsSizes = sizes
  }

  const do_filter =(state, {data, pagination}) => {
    state.filtered = data
    state.pagination = pagination
  }
  const clear_filters =(state)=>{
    state.filtered=null
    state.activeFilters=[]
  }
  const do_sort =(state, {sorting}) => {
    
    switch(sorting.type){
      case 'Nome asc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => a.title.localeCompare(b.title))
        }else{
          state.campaigns = state.campaigns.sort((a, b) => a.title.localeCompare(b.title))
        }
        
      break
      case 'Nome desc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => b.title.localeCompare(a.title))
        }else{
          state.campaigns = state.campaigns.sort((a, b) => b.title.localeCompare(a.title))
        }
      break
      case 'Nome Advertiser asc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => a.advertiserUser.userName.localeCompare(b.advertiserUser.userName))
        }else{
          state.campaigns = state.campaigns.sort((a, b) => a.advertiserUser.userName.localeCompare(b.advertiserUser.userName))
        }
        
      break
      case 'Nome Advertiser desc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => b.advertiserUser.userName.localeCompare(a.advertiserUser.userName))
        }else{
          state.campaigns = state.campaigns.sort((a, b) => b.advertiserUser.userName.localeCompare(a.advertiserUser.userName))
        }
      break
      case 'data creazione asc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        }else{
          state.campaigns = state.campaigns.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        }
        
      break
      case 'data creazione desc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        }else{
          state.campaigns = state.campaigns.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        }
      break
    }
    
  }

  const single_get_success = (state, {data})=>{
      
      const toReplace = state.campaigns.find(c =>c.id === data.id) 
      if(toReplace){
        Object.assign(toReplace, data)
      } else  {
        state.campaigns.unshift(data)
      }
      state.single = data
  }
  const monetize_success = (state, campaign)=>{
      const toReplace = state.campaigns.find(c =>c.id === campaign.id) 
      if(toReplace){
        Object.assign(toReplace, campaign);
      }
      
  }
  
  const social_campaign_authorized = (state, {socialId, campaignId, authorized})=>{
    const campaign = state.campaigns.find(c =>c.id === campaignId) 
    if(campaign.socialCampaigns){
      const social = campaign.socialCampaigns.find(s=>s.socialId === socialId)
      social.authorized = authorized
    }
   
  }

  const social_campaign_accepted = (state, {socialId, campaignId, accepted})=>{

   
    const campaign = state.campaigns.find(c =>c.id === campaignId) 
    if(campaign.socialCampaigns){
      const social = campaign.socialCampaigns.find(s=>s.socialId === socialId) 
      social.accepted = accepted
    }
    
    
  }

  const approved_socials_success =(state,socials)=>{
    state.loading = false
    state.facebookPages = socials

  }
  const delete_event_success =(state)=>{
    state.loading = false
      

  }
  const delete_event_error =(state)=>{
    state.loading = false
      

  }

  const analytics_socials_success =(state,socials)=>{
    state.loading = false
    state.analyticsPages = socials

  }
  const analytics_socials_request =(state)=>{
    state.loading = true
    state.analyticsPages = []

  }

  const social_campaign_price_updated = (state/* , {socialId, campaignId, data} */) =>{
    state.loading = false
    

  }

  const publishdate_error =(state)=>{
    
    state.loading = false
    
}

const publishdate_success = (state) => {   
    state.loading = false
}

const empty_filter = (state)=>{
  state.filtered = null
}
  export const mutations = {
    request,
    get_success,
    get_error,
    by_tag_success,
    clear,
    save_request,
    save_error,
    save_success,
    update_success,
    tag_update_error,
    tag_update_success,
    remove_error,
    remove_success,
    savecolumnsizes,
    do_filter,
    do_sort,
    social_sync_success,
    social_sync_error,
    social_remove_success,
    single_get_success,
    social_campaign_authorized,
    social_campaign_accepted,
    monetize_success,
    approved_socials_success,
    social_campaign_price_updated,
    analytics_socials_success,
    analytics_socials_request,
    publishdate_error,
    publishdate_success,
    delete_event_success,
    delete_event_error,
    empty_filter,
    clear_filters
  }
  