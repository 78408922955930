import { ApiEndPoints } from '../../api/Endpoints';
import { API } from '@/api/API';

/**
 * Richiede la lista degli user-scopes
 */
const userScopes = async({commit}, payload)=>{
  commit('userscope_request')
  try {
    const {data, headers} = await API.get(ApiEndPoints.USER_SCOPE, payload)
    const pagination = JSON.parse(headers['x-pagination'])
    commit('userscope_success' ,{ data, pagination })
  } catch (error) {
    commit('userscope_error')
  }
}

const createUserScope = async({commit}, scope)=>{
  commit('userscope_request')
  const {name, discriminator, concessionaireId} = scope
  const data = {
    name:name.value, 
    discriminator:discriminator
  }
  if(concessionaireId){
    data.concessionaireId = concessionaireId
  }
  try {
    const response = await API.post(ApiEndPoints.USER_SCOPE, data)
    commit('userscope_save_success', response) 
    return response
  } catch (error) {
    commit('userscope_save_error')
    throw new Error(error.message)
  }
}

const filter = async ({commit, state}, filter) =>{
  const params = {}
  const {type, value} = filter
  commit('filter_request',{type, value})
  const activeFilters = state.activeFilters
  if(activeFilters.length === 0){
    commit('empty_filter', type)
    try {
      const {data, headers} = await API.get(ApiEndPoints.USER_SCOPE, {})
      const pagination = JSON.parse(headers['x-pagination'])
      commit('userscope_success' ,{ data, pagination })
      return data
    } catch (error) {
      commit('userscope_error')
      throw new Error(error.message)
    }
    
  } else {
    let url , search, discriminator
    switch (type) {
      case 'search':
          url = ApiEndPoints.USER_SCOPE + '?' + type + '=' +value
          discriminator = activeFilters.find(f => f.type === 'discriminator')
          if(discriminator){
            url +='&discriminator='+discriminator.value
          } 
        break;
        case 'discriminator':
          search = activeFilters.find(f => f.type === 'search')
          if(value !== 'Tutti'){
            url = ApiEndPoints.USER_SCOPE + '?' + type + '=' +value
            discriminator = value
            if(search){
              url += '&search='+search.value
            }
          } else {
            if(search){
              url = ApiEndPoints.USER_SCOPE + '?search='+search.value
            } else {
              url = ApiEndPoints.USER_SCOPE
            }
            
          }
         
         
          
        break;
      default:
        break;
    }
    try {
      const {data, headers} = await API.get(url, params)
      const pagination = JSON.parse(headers['x-pagination'])
      commit('do_filter', {data, pagination})
      return data
    } catch (error) {
      commit('social_error')
      throw new Error(error.message)
    }  
  }
  
  
}


const sort = async ({commit}, sorting) =>{
  commit('do_sort', {sorting})
}
const clearList = async ({commit}) =>{
  commit('userscope_clear')

}

const update = async({commit},{id,data})=>{
  commit('update_request')
  try {
    await API.put(ApiEndPoints.USER_SCOPE+'/'+id, data)
    commit('update_success', {data}) 
    return data
  } catch (error) {
    commit('userscope_error')
    throw new Error(error.message)
  }
}


const remove = async({commit}, id)=>{
  commit('userscope_request')
  try {
    await API.delete(ApiEndPoints.USER_SCOPE+'/'+ id )
    commit('remove_success' ,{id})
    
  } catch (error) {
    commit('remove_error')
    throw new Error(error.message)
  } 
}

const upload = async({commit},fileData)=>{
  commit('userscope_request')
  try {
    const formData = new FormData();
    formData.append('file', fileData.logo)
    const response = await API.post(ApiEndPoints.USER_SCOPE+'/'+ fileData.id +'/logo', formData)
    commit('upload_success' ,{response})
    return response
  } catch (error) {
    commit('userscope_error')
    throw new Error(error.message)
  }
}

const getSingle = async({commit}, id)=>{
  
  commit('userscope_request')
  try {
    //const userScope = await API.get(ApiEndPoints.USER_SCOPE+'/'+id)
    const {data} = await API.get(ApiEndPoints.USER_SCOPE+'/'+id)
    commit('single_get_success', {data}) 
    return data
  } catch (error) {
    commit('save_error')
    throw new Error(error.message)
  }
}

export  const actions = {
    userScopes,
    createUserScope,
    filter,
    clearList,
    update,
    remove,
    sort,
    upload,
    getSingle
}