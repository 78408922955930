const social_request = (state) => {
  state.loading = true;
};
const social_success = (state, { data, pagination }) => {
  state.loading = false;

  const { CurrentPage } = pagination;
  //prima navigazione
  if (!state.pagination) {
    state.pagination = pagination;
    state.social = data;
    state.visitedPages.push(CurrentPage);
  } else {
    state.pagination = pagination;
    ///se non ho mai visto la paginata -> aggiungo i nuovi dati

    if (!state.visitedPages.find((p) => p === CurrentPage)) {
      state.visitedPages.push(CurrentPage);
      data.forEach((s /* , indx */) => {
        if (!state.social.find((so) => so.socialId === s.socialId)) {
          state.social.push(s);
        }
      });
    } else {
      ////altrimenti eseguo l'update sulla porzione di store e lascio il resto invariato

      data.forEach((s, indx) => {
        const toReplace = state.social.find((so) => so.socialId === s.socialId);
        if (toReplace) {
          Object.assign(toReplace, s);
        } else {
          state.social[indx] = s;
        }
      });
    }

    state.pagination = pagination;
  }
};
const social_error = (state) => {
  state.loading = false;
};
const fb_request = (state) => {
  state.loading = true;
};
const fb_success = (state, { data, pagination }) => {
  state.loading = false;
  state.social = data;
  state.pagination = pagination;
};
const fb_error = (state) => {
  state.loading = false;
};
const social_selected = (state, { social }) => {
  state.selected = social;
};
const social_save_request = (state) => {
  state.loading = true;
};
const social_save_success = (state, data) => {
  state.loading = false;
  state.social.unshift(data);
};
const social_save_error = (state) => {
  state.loading = false;
};
const social_by_tag_success = (state, { data }) => {
  state.social = data;
};
const social_clear = (state) => {
  state.social = [];
};

const filter_request = (state, { type, value }) => {
  state.loading = true;
  const indx = state.activeFilters.findIndex((f) => f.type === type);
  const emptySearch = type === "search" && value === "";
  const allRoles = type === "discriminator" && value === "Tutti";
  if (indx === -1) {
    if (type === "discriminator" && !allRoles) {
      state.activeFilters.push({ type: type, value: value });
    }
    if (type === "search" && !emptySearch) {
      state.activeFilters.push({ type: type, value: value });
    }
  } else {
    if (type === "search") {
      if (emptySearch) {
        state.activeFilters = state.activeFilters.filter(
          (f) => f.type !== "search"
        );
      } else {
        state.activeFilters[indx]["value"] = value;
      }
    }
    if (type === "discriminator") {
      if (allRoles) {
        state.activeFilters = state.activeFilters.filter(
          (f) => f.type !== "discriminator"
        );
      } else {
        state.activeFilters[indx]["value"] = value;
      }
    }
  }
};
const do_filter = (state, { data, pagination }) => {
  state.filtered = data;
  state.pagination = pagination;
};
const empty_filter = (state, type) => {
  const indx = state.activeFilters.findIndex((f) => f.type === type);
  if (indx !== -1) {
    state.activeFilters = state.activeFilters.filter((f) => f.type !== type);
  }
  if (state.activeFilters.length === 0) {
    state.filtered = null;
  }
};
const clear_filters = (state) => {
  state.filtered = null;
  state.activeFilters = [];
  state.visitedPages = [];
};

const do_sort = (state, { sorting }) => {
  switch (sorting.type) {
    case "Nome asc":
      if (state.filtered) {
        state.filtered = state.filtered.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } else {
        state.social = state.social.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
      break;
    case "Nome desc":
      if (state.filtered) {
        state.filtered = state.filtered.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
      } else {
        state.social = state.social.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
      }
      break;
    case "data creazione asc":
      if (state.filtered) {
        state.filtered = state.filtered.sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt)
        );
      } else {
        state.social = state.social.sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt)
        );
      }
      break;
    case "data creazione desc":
      if (state.filtered) {
        state.filtered = state.filtered.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );
      } else {
        state.social = state.social.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );
      }
      break;
  }
};

const social_edit = (state, { editing }) => {
  state.editing = editing;
};

const social_tag_update_error = (state) => {
  state.loading = false;
};
const social_fetch_error = (state) => {
  state.loading = false;
};
const social_tag_update_success = (state) => {
  state.loading = false;
};
const social_fetch_success = (state, { data }) => {
  const social = state.social.find((s) => s.socialId === data.socialId);
  Object.assign(social, data);
};

const fb_reset_token_success = (state) => {
  state.loading = false;
};

const fb_refresh_token_success = (state) => {
  state.loading = false;
};

const fb_get_pages_success = (state) => {
  state.loading = false;
};

const remove_error = (state) => {
  state.loading = false;
};
const remove_success = (state, social) => {
  state.loading = false;
  const newSocials = state.social.filter((s) => s.socialId !== social.id);
  state.social = newSocials;
};
const update_success = (state, { response }) => {
  state.loading = false;
  const updated = state.social.find(
    (social) => social.socialId === response.socialId
  );
  if (updated) {
    Object.assign(updated, response);
  }

  state.selected = response;
};
const savecolumnsizes = (state, sizes) => {
  state.columnsSizes = sizes;
};
const single_get_success = (state, { data }) => {
  const toReplace = state.social.find((s) => s.id === data.id);
  if (toReplace) {
    Object.assign(toReplace, data);
  }
};

export const mutations = {
  social_request,
  social_success,
  social_error,
  fb_request,
  fb_success,
  fb_error,
  social_selected,
  social_save_request,
  social_save_success,
  social_save_error,
  social_by_tag_success,
  social_clear,
  do_filter,
  filter_request,
  do_sort,
  social_edit,
  social_tag_update_error,
  social_tag_update_success,
  social_fetch_success,
  social_fetch_error,
  remove_error,
  remove_success,
  update_success,
  savecolumnsizes,
  single_get_success,
  fb_reset_token_success,
  fb_refresh_token_success,
  fb_get_pages_success,
  empty_filter,
  clear_filters,
};
