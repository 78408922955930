export const quotesModule = {
  state: () => ({
    quotes: [],
  }),
  mutations: {
    setQuotes(state, quotes) {
      state.quotes = quotes;
    },
    addQuote(state, quote) {
      state.quotes.push(quote);
    },
    // Aggiungi altre mutations qui se necessario
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchQuotes({ commit }) {
      // Qui dovresti fare una chiamata API per ottenere i preventivi
      // e poi chiamare la mutation setQuotes
    },
    // eslint-disable-next-line no-unused-vars
    createQuote({ commit }, quote) {
      // Qui dovresti fare una chiamata API per creare un preventivo
      // e poi chiamare la mutation addQuote
    },
    // Aggiungi altre actions qui se necessario
  },
  getters: {
    quotes: (state) => state.quotes,
    // Aggiungi altri getters qui se necessario
  },
};
