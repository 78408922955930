const List = () => import("@/views/Quotes.vue");
const Edit = () => import("@/components/quotes/EditQuote.vue");
const Preview = () => import("@/views/QuotePreview.vue");

export const quotes = {
  path: "/quotes",
  name: "quotes",
  component: List,
  meta: {
    requiresAuth: true,
  },
};

export const editQuote = {
  path: "/quotes/edit/:id?",
  name: "editQuote",
  component: Edit,
  meta: {
    requiresAuth: true,
  },
};

export const quotePreview = {
  path: "/quotes/preview/:cod",
  name: "quotePreview",
  component: Preview,
  meta: {
    requiresAuth: false,
  },
};
