import { mutations } from './mutations';
import { actions } from './actions';




export const socialModule = {
    state () {
        return {
          social:[],  
          facebook:[],
          instagram:[],
          editing:false, 
          filtered:null,
          activeFilters:[],
          columnsSizes:null,
          pagination:null,
          visitedPages:[]
        }
    },
    namespaced: true,
    getters: {
        social:state =>state.social,
        paged: state => {

            const currentPage = state.pagination?.CurrentPage || 1
            const pageSize = state.pagination?.PageSize || 20
            const offset = (currentPage * pageSize) - pageSize
            return state.social.filter((tag, index) => (index >= offset && index <= offset + pageSize -1))
        },
        facebook: state => state.social.filter(s =>s.discriminator==='Facebook'),
        instagram: state => state.social.filter(s =>s.discriminator==='Instagram'),
        editing:state=>state.editing,
        filtered:state=>state.filtered,
        activeFilters:state=>state.activeFilters,
        columnsSizes:state=>state.columnsSizes,
        pagination:state=>state.pagination
    },
    mutations,
    actions
}