<template>
    <label class="flex-container flex-col flex-0 mb8">
       <div class="flex-container heading-and-error flex-start-align no-grow">
            <h3 class="bebas nomargin">User Role</h3>
            
        </div>
        
        <select v-if="editing" class="noborder nopadding pl8" @change="onRoleSelect" name="role" :key="'select-'+keysuffix">
            <option :value="selected.roles[0]" selected>{{selected.roles[0]}}</option>
            <option 
                v-for="role in roles" 
                :key="role.name" 
                :value="role.name"
            >
                {{role.name}}
            </option>
        </select>
        <select v-else class="noborder nopadding pl8" disabled>
            <option :value="selected.roles[0]">{{selected.roles[0]}}</option>
        </select>
    </label>
</template>
<script>
import {computed} from 'vue'
import {Roles} from '../../utils/RolesAndPolicies'
import {useCapabilities} from '@/utils/Capabilities'

export default {
    emits:['change-role'],
    props:{
        selected: {
            type:Object,
            default:()=>{}
        },
        editing:{
            type:Boolean,
            default:true
        },
        keysuffix:{
            type:Number,
            default:0
        },
    },
    components:{
       // ErrorMessage
    },
    setup(props,{emit}){
        const onRoleSelect = async (event)=>{

            const newRole = event.target.value
            let discriminator
            
            if(newRole === 'Admin' || newRole === 'Advertiser'){
                discriminator = null
            } else if(newRole === 'PublisherAdmin' || newRole === 'PublisherUser'){
                discriminator = 'Publisher'
            }else{
                discriminator = 'Concessionaire'
            }
            emit('change-role', {
                role:event.target.value,
                discriminator:discriminator
            })
        }
        const { isPublisher, isConcessionaire} = useCapabilities()
    
        
        const roles = computed(()=> {
           if(isPublisher() || isConcessionaire()){
                
              return Roles.filter(r => r.name !== props.selected.roles[0] && r.name.startsWith('Publisher'))
           } 
           return Roles.filter(r => r.name !== props.selected.roles[0] && r.name !== 'Superuser')
        })

        return{
            onRoleSelect,
            roles
        }
    }
}
</script>