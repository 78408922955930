import { mutations } from './mutations';
import { actions } from './actions';



export const campaignsModule = {
    state () {
        return {
          campaigns:[],
          filtered:null,
          columnsSizes:null,
          facebookPages:[],
          analyticsPages:[],
          pagination:null,
          single:null,
          visitedPages:[]
        }
    },
    namespaced: true,
    getters: {
        campaigns: state => state.campaigns,
        paged: state => {

            const currentPage = state.pagination.CurrentPage
            const pageSize = state.pagination.PageSize
            const offset = (currentPage * pageSize) - pageSize
            return state.campaigns.filter((campaign, index) => (index >= offset && index <= offset + pageSize -1))
        },
        columnsSizes:state=>state.columnsSizes,
        filtered:state=>state.filtered,
        facebookPages:state=>state.facebookPages,
        analyticsPages:state=>state.analyticsPages,
        pagination:state=>state.pagination,
        single:state => state.single
    },
    mutations,
    actions
}